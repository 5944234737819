<template>
  <div class="contents">
    <div class="container-fluid">
      <div class="profile-content mb-50">
        <div class="row">
          <div class="col-lg-12 mb-2 sticky">
            <div
              class="breadcrumb-main p-3"
              style="background-color: #f4f5f7 !important"
            >
              <h4 class="text-capitalize breadcrumb-title">
                Documentation
                <ul class="atbd-breadcrumb nav">
                  <li class="atbd-breadcrumb__item">
                    <router-link to="/">
                      <span class="la la-home"></span>
                    </router-link>
                    <span class="breadcrumb__seperator">
                      <span class="la la-slash"></span>
                    </span>
                  </li>
                  <li class="atbd-breadcrumb__item">
                    <span class="bread-item" style="color: #9299b8"
                      >Action</span
                    >
                    <span class="breadcrumb__seperator">
                      <span class="la la-slash"></span>
                    </span>
                  </li>
                  <li class="atbd-breadcrumb__item">
                    <span class="bread-item">Documentation</span>
                  </li>
                </ul>
              </h4>
              <div class="action-btn">
                <router-link
                  :to="{ name: 'action-documentation-toc-edit' }"
                  class="btn btn-sm btn-primary btn-add"
                >
                  <i class="la la-plus"></i> Add
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <div class="">
            <div class="contact-list-wrap mb-25">
              <div class="contact-list bg-white radius-xl w-100">
                <div class="table-responsive">
                  <table class="table mb-0 table-borderless table-rounded">
                    <thead>
                      <tr class="">
                        <th scope="col">
                          <span class="userDatatable-title">Title</span>
                        </th>
                        <th scope="col">
                          <span class="userDatatable-title">Contents</span>
                        </th>
                        <th scope="col">
                          <span class="userDatatable-title float-right"
                            >Actions</span
                          >
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="doc in docs" :key="doc.id">
                        <td>
                          <div class="d-flex align-items-center">
                            <div class="orderDatatable-title">
                              <p class="d-block mb-0">{{doc.title}}</p>
                            </div>
                          </div>
                        </td>

                        <td>
                          {{doc.description}}
                        </td>
                        <td>
                          <ul
                            class="
                              orderDatatable_actions
                              mb-0
                              d-flex
                              flex-wrap
                              float-right
                            "
                          >
                            <li>
                              <a
                                href="#"
                                class="remove"
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                data-original-title="view question"
                              >
                                <i class="las la-eye"></i>
                              </a>
                            </li>
                            <li>
                              <router-link
                                :to="{name: 'action-documentation-toc-edit', params: {doc}}"
                                class="remove"
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                data-original-title="Edit"
                              >
                                <i class="las la-edit"></i>
                              </router-link>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import db from "@/plugins/localdb";
import axios from "axios";
export default {
  data() {
    return {
      markdown: "",
      editorConfig: {},
      docs: [],
      url: "/download/help.json",
    };
  },
  async created() {
    axios
      .get(this.url)
      .then((res) => {
        this.docs = res.data;
        db.docs.bulkAdd(this.docs);
      })
      .catch((err) => console.log(err, "an error occured"));

    // console.log(this.docs)
    // this.docs = await db.docs.toArray();
  },
  methods: {},
};
</script>